import { debug } from '~/utils/logging'

export default function useLoginLinks() {
  const { locale } = useI18n()

  const { data, status, isAuthenticated, signIn, signOut } = useAuthLinks({
    locale,
  })

  const { isAllowed } = usePermissions(PERMISSIONS.DAILY_WORD)
  const { dailyWordSubscribeUrl } = useAppConfig()

  const subscribe = async () => {
    const lang = (locale.value || 'en') as 'en' | 'es'
    const path = dailyWordSubscribeUrl[lang]
    return await navigateTo(path, { external: true })
  }

  const canAccessDailyWord = computed(() => isAllowed.value)

  watch(status, (newVal, oldVal) => {
    debug(`[login] status changed from "${oldVal}" to "${newVal}"`)
  })

  return {
    data,
    status,
    isAuthenticated,
    canAccessDailyWord,
    signIn,
    signOut,
    subscribe,
  }
}
