import type { ComputedRef, Ref, UnwrapRef } from 'vue'

type MaybeRef<T> = T | Ref<T>

type ExcludeOptional<T> = Exclude<T, null | undefined>

type UseSessionReturn = ExcludeOptional<
  UnwrapRef<ReturnType<typeof useAuth>['data']>
>
type User = ExcludeOptional<UseSessionReturn['user']>

export interface DrupalUser extends User {
  readonly subscriptions?: string[]
}

export type UsePermissionsOptions = {
  conjunction: 'AND' | 'OR'
}

export type UsePermissions = {
  isAllowed: ComputedRef<boolean>
  isDenied: ComputedRef<boolean>
}

export enum PERMISSIONS {
  DAILY_WORD = 'can access EDW',
  UNITY_MAGAZINE = 'can access UM',
}

const defaultOptions: UsePermissionsOptions = {
  conjunction: 'AND',
}

const userSubscriptions = (user: User) => {
  const subs: PERMISSIONS[] = []
  if (!user) return subs

  if (user.hasActiveEdwSubscription) {
    subs.push(PERMISSIONS.DAILY_WORD)
  }

  if (user.hasActiveUmgSubscription) {
    subs.push(PERMISSIONS.UNITY_MAGAZINE)
  }

  return subs
}

export function usePermissions(
  permissions: MaybeRef<PERMISSIONS | PERMISSIONS[]>,
  options?: UsePermissionsOptions,
): UsePermissions
export function usePermissions<U extends User = DrupalUser>(
  permissions: MaybeRef<PERMISSIONS | PERMISSIONS[]>,
  user: U,
  options?: UsePermissionsOptions,
): UsePermissions
export function usePermissions<U extends User = DrupalUser>(
  permissions: MaybeRef<PERMISSIONS | PERMISSIONS[]>,
  arg2?: U | UsePermissionsOptions,
  arg3?: UsePermissionsOptions,
): UsePermissions {
  const session = useAuth()

  const { conjunction } = {
    ...defaultOptions,
    ...(arg3 ? arg3 : arg2 ? arg2 : {}),
  }

  const user = computed(() => {
    if (arg3) return arg2 as U

    return session.data.value?.user || undefined
  })

  const isAllowed = computed<boolean>(() => {
    const _permissions = unref(permissions)
    const _perms = Array.isArray(_permissions) ? _permissions : [_permissions]
    const _user = unref(user) as Required<DrupalUser>

    if (!_perms?.length) return false
    if (!_user) return false

    const subscriptions = userSubscriptions(_user)

    if (!subscriptions.length) return false

    const _conjunction = unref(conjunction)

    const test =
      _conjunction === 'OR'
        ? (prev: boolean, perm: PERMISSIONS): boolean =>
            prev || subscriptions.includes(perm)
        : (prev: boolean, perm: PERMISSIONS): boolean =>
            prev && subscriptions.includes(perm)

    return _perms.reduce(test, _conjunction !== 'OR')
  })

  const isDenied = computed<boolean>(() => !isAllowed.value)

  return { isAllowed, isDenied }
}
