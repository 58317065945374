<template>
  <button v-bind="$attrs" v-if="isAuthenticated" @click="signOut()">
    <slot name="logout">
      {{ t('Sign out') }}
    </slot>
  </button>
  <button v-bind="$attrs" v-else @click="signIn()">
    <slot name="login">
      {{ t('Sign in') }}
    </slot>
  </button>
</template>

<script lang="ts" setup>
const { t } = useI18n()

const { status, isAuthenticated, signIn, signOut } = useLoginLinks()
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>

<i18n lang="json">
{
  "en": {
    "Sign in": "Sign in",
    "Sign out": "Sign out"
  },
  "es": {
    "Sign in": "Inicia",
    "Sign out": "Desconectar"
  }
}
</i18n>
