type UseLoginLinksOptions = {
  logger?: typeof console
  locale: MaybeRef<string>
}

export function useDummyAuthLinks({ logger = console }: UseLoginLinksOptions) {
  const { data, status, signIn: _signIn, signOut: _signOut } = useAuth()

  type SignInOptions = Exclude<Parameters<typeof _signIn>[1], undefined>
  type SignOutOptions = Exclude<Parameters<typeof _signOut>[0], undefined>

  const signIn = (options?: SignInOptions) => {
    const provider = 'credentials'
    logger.log(`Redirecting sign-in to provider "${provider}"`)

    _signIn(provider, {
      ...options,
      username: 'Jimmy Neutron',
      password: 'neutron1',
      redirect: false,
    })
  }

  const signOut = (options?: SignOutOptions) =>
    _signOut({ redirect: false, ...options })

  const isAuthenticated = computed(() => status.value === 'authenticated')

  return {
    data,
    status,
    isAuthenticated,
    signIn,
    signOut,
  }
}

function useProdAuthLinks({ locale, logger = console }: UseLoginLinksOptions) {
  const { data, status, signIn: _signIn, signOut: _signOut } = useAuth()

  type SignInOptions = Exclude<Parameters<typeof _signIn>[1], undefined>
  type SignOutOptions = Exclude<Parameters<typeof _signOut>[0], undefined>

  const signIn = (options?: SignInOptions) => {
    const lang = unref(locale)
    const params = {
      ui_locales: lang,
    }
    const _options = { redirect: false, ...options }
    const provider = `advantage${lang === 'es' ? '-es' : ''}`
    logger.log(`Redirecting sign-in to provider "${provider}"`)

    return _signIn(provider, _options, params)
  }

  const signOut = (options?: SignOutOptions) =>
    _signOut({ redirect: false, ...options })

  const isAuthenticated = ref<boolean>(unref(status) === 'authenticated')

  watch(
    status,
    (newVal: string, oldVal: string) => {
      if (import.meta.client) {
        logger.debug(
          `[useAuthLinks] status changed from "${oldVal}" to "${newVal}"`,
        )
      }
      isAuthenticated.value = newVal === 'authenticated'
    },
    { immediate: true },
  )

  return {
    data,
    status,
    isAuthenticated,
    signIn,
    signOut,
  }
}

export default function useAuthLinks(options: UseLoginLinksOptions) {
  const { data, status, isAuthenticated, signIn, signOut } = (
    import.meta.dev ? useDummyAuthLinks : useProdAuthLinks
  )(options)

  const logger = options.logger || console

  watch(status, (newVal: string, oldVal: string) => {
    logger.debug(`[login] status changed from "${oldVal}" to "${newVal}"`)
  })

  return {
    data,
    status,
    isAuthenticated,
    signIn,
    signOut,
  }
}
